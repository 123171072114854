$(document).ready(function() {

	
 //Ajax отправка форм
    $(".call-form").submit(function() {
        $.ajax({
            type: "POST",
            context: this,
            url: "/wp-content/themes/alehina/mail.php",
            data: $(this).serialize()
        }).done(function() {

            var magnificPopup = $.magnificPopup.instance;
            magnificPopup.close();

            magnificPopup.open({
                items: {
                    src: '#sucess-popup', // can be a HTML string, jQuery object, or CSS selector
                    type: 'inline',
                    midClick: true,
                    closeBtnInside: true
                }
            });

            //alert("Спасибо за заявку!");
            setTimeout(function() {

                $(".call-form").trigger("reset");
            }, 1000);

        });
        return false;
    });

	
	// owl-carousel
	$("#recomends-slider").owlCarousel({
		singleItem : true, 
		navigation : true,
		paginationNumbers : false,
		pagination : false,
		navigationText : false

	});

	$('.mobile-mnu').affix({
		offset: 370
	});

	$(".top-menu").affix ({
		offset: 150
	});

	// toggle-menu

	$(".toggle-lines-wrp").on("click", function () {

		$(".mobile-toggle-menu-body").toggleClass("hide-toggle-menu")
		$(".toggle-menu-line-1").toggleClass("turn45-r")
		$(".toggle-menu-line-2").toggleClass("hide-elem")
		$(".toggle-menu-line-3").toggleClass("turn45-l")

	});

	//Цели для Яндекс.Метрики и Google Analytics
	$(".count_element").on("click", (function() {
		ga("send", "event", "goal", "goal");
		yaCounterXXXXXXXX.reachGoal("goal");
		return true;
	}));

	//SVG Fallback
	if(!Modernizr.svg) {
		$("img[src*='svg']").attr("src", function() {
			return $(this).attr("src").replace(".svg", ".png");
		});
	};

	// //Аякс отправка форм
	// //Документация: http://api.jquery.com/jquery.ajax/
	// $("#form").submit(function() {
	// 	$.ajax({
	// 		type: "POST",
	// 		url: "mail.php",
	// 		data: $(this).serialize()
	// 	}).done(function() {
	// 		alert("Спасибо за заявку!");
	// 		setTimeout(function() {
				
	// 			$("#form").trigger("reset");
	// 		}, 1000);
	// 	});
	// 	return false;
	// });

	//Chrome Smooth Scroll
	try {
		$.browserSelector();
		if($("html").hasClass("chrome")) {
			$.smoothScroll();
		}
	} catch(err) {

	};

	$("img, a").on("dragstart", function(event) { event.preventDefault(); });

	// Magnific Popup
	$('.submit-button-menu').magnificPopup({
	items: {
		 src: "#call-popup"
		},
    midClick: true,
    type: "inline"
   
	});
	
});

$(window).load(function() {
	// equalHeights
	

	// if ($(window).width() > 992) {
	// 	$('.s-cases .equal-wrp--cases').equalHeights();
	// } else {
	// 	$('.s-cases .equal-wrp--cases').css('height','auto');
	// }

	
	// $(".loader_inner").fadeOut();
	// $(".loader").delay(400).fadeOut("slow");

  $(".item_s-services .icon-wrp--item_s-services").equalHeights();
  $(".equal-main-s").equalHeights();
  $(".item_team").equalHeights();
  $(".item_s-services--adv").equalHeights();
	$(".icon-wrp--item_s-services--adv").equalHeights();
	$(".adv-s_about-pg--list").equalHeights();
	$(".item_serv-detailed").equalHeights();
	$(".heading_blog").equalHeights();
	$(".img-wrp_blog").equalHeights();
	$(".blog-article").equalHeights();
});

$(window).resize(function () {
	// equalHeights
	

	// if ($(window).width() > 992) {
	// 	$('.s-cases .equal-wrp--cases').css('height','auto');
	// 	$('.s-cases .equal-wrp--cases').equalHeights();
	// } else {
	// 	$('.s-cases .equal-wrp--cases').css('height','auto');
	// }

	$(".item_s-services .icon-wrp--item_s-services").css('height','auto');
	$(".item_s-services .icon-wrp--item_s-services").equalHeights();
	$(".equal-main-s").css('height','auto');
	$(".equal-main-s").equalHeights();
	$(".item_s-services--adv").css('height','auto');
	$(".item_s-services--adv").equalHeights();
	$(".icon-wrp--item_s-services--adv").css('height','auto');
	$(".icon-wrp--item_s-services--adv").equalHeights();
	$(".item_team").css('height','auto');
	$(".item_team").equalHeights();
	$(".adv-s_about-pg--list").css('height','auto');
	$(".adv-s_about-pg--list").equalHeights();
	$(".item_serv-detailed").css('height','auto');
	$(".item_serv-detailed").equalHeights();
	$(".heading_blog").css('height','auto');
	$(".heading_blog").equalHeights();
	$(".img-wrp_blog").css('height','auto');
	$(".img-wrp_blog").equalHeights();
	$(".blog-article").css('height','auto');
	$(".blog-article").equalHeights();

});